<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />
    <!-- 顶部图片 -->
    <div class="header">
		<!-- <span>COMPANY OVERVIEW</span> -->
		<img src="../../assets/index/co.jpg" alt="">
	</div>
    <div class="title">
      <p v-for="(item, index) in textContentList" :key="index">
        {{ item.content }}
      </p>
    </div>

    <!-- 关于 对话 About DataLogic -->
    <div class="about">
      <div class="about_box" v-for="(item, index) in aboutList" :key="index">
        <div
          :class="item.url ? 'about_box_img ' : 'about_box_img about_box_img2'"
        >
          <img :src="item.url" alt="" />
        </div>
        <div
          :class="
            item.url ? 'about_box_title ' : 'about_box_title about_box_title2'
          "
        >
          <div>{{ item.title }}</div>
          <div>
            <p v-for="(item2, index2) in item.contentList" :key="index2">
              {{ item2.content }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/breadcrumb/breadcrumb";
import api from "@/utils/api";
export default {
  mounted() {
    //面包屑导航
    this.breadcrumbList = [
      { path: "/", title: "HOME" },
      { path: "/contactus", title: "CONTACT US" },
      { path: "", title: 'COMPANY OVERVIEW' },
    ];
    this.companyProfile();
  },
  data() {
    return {
      breadcrumbList: [],
      aboutList: [
        {
          img: require("@/assets/index/about1.png"),
          title: "ABOUT DATALOGIC",
          content: `Datalogic is a global technology leader in the automatic data
            capture and factory automation markets, specialized in the designing
            and prod uction of bar code readers, mobile computers, sensors for
            detection, m easurement and safety, RFID, vision and laser marking
            systems. Closen ess and timely response to every customer
            requirements, together with continuous innovation and high quality
            offering, are the distinctive ele ments at the base of Datalogic
            success for over 40 years. Its cutting-ed ge...`,
        },
        {
          img: require("@/assets/index/about2.png"),
          title: "ABOUT DATALOGIC",
          content: `Datalogic is a global technology leader in the automatic data
            capture and factory automation markets, specialized in the designing
            and prod uction of bar code readers, mobile computers, sensors for
            detection, m easurement and safety, RFID, vision and laser marking
            systems. Closen ess and timely response to every customer
            requirements, together with continuous innovation and high quality
            offering, are the distinctive ele ments at the base of Datalogic
            success for over 40 years. Its cutting-ed ge...`,
        },
        {
          img: require("@/assets/index/about1.png"),
          title: "ABOUT DATALOGIC",
          content: `Datalogic is a global technology leader in the automatic data
            capture and factory automation markets, specialized in the designing
            and prod uction of bar code readers, mobile computers, sensors for
            detection, m easurement and safety, RFID, vision and laser marking
            systems. Closen ess and timely response to every customer
            requirements, together with continuous innovation and high quality
            offering, are the distinctive ele ments at the base of Datalogic
            success for over 40 years. Its cutting-ed ge...`,
        },
        {
          img: require("@/assets/index/about2.png"),
          title: "ABOUT DATALOGIC",
          content: `Datalogic is a global technology leader in the automatic data
            capture and factory automation markets, specialized in the designing
            and prod uction of bar code readers, mobile computers, sensors for
            detection, m easurement and safety, RFID, vision and laser marking
            systems. Closen ess and timely response to every customer
            requirements, together with continuous innovation and high quality
            offering, are the distinctive ele ments at the base of Datalogic
            success for over 40 years. Its cutting-ed ge...`,
        },
      ], //关于模块信息（其他类容）
      textContentList: [], //文本内容
    };
  },
  methods: {
    //获取公司简介
    companyProfile() {
      api.companyProfile().then((res) => {
        let data = res.data;
        if (data.code == 200) {
          // console.log(data);
          this.textContentList = data.data.textContentList;
          this.aboutList = data.data.otherContentList;
          // console.log(this.aboutList);
        } else {
          this.$message.error(data.msg);
        }
      });
    },
  },
  components: {
    Breadcrumb,
  },
};
</script>

<style lang="scss" scoped>
.header {
  // background-image: url('../../assets/index/carouselnew.jpg');
  background-repeat: no-repeat;
  background-size: 100%;
  // height: 186px;
  min-width: 1156px;
  font-size: 56px;
  font-family: Arial;
  font-weight: bold;
  color: #ffffff;
  // line-height: 186px;
  text-align: center;
  position: relative;
  img{
	  max-width: 100%;
  }
  span{
	  position: absolute;
	  top:50%;
	  left: 50%;
	  transform: translate(-50%,-50%);
  }
}
.title {
  margin: 50px auto 57px;
  width: 1136px;
  line-height: 24px;
  font-size: 16px;
  font-family: Arial;
  font-weight: normal;
  color: #5a5b5e;
}

.about {
  .about_box {
    max-height: 586px;
    display: flex;
    min-width: 1156px;
    .about_box_img {
      width: 50%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .about_box_title {
      width: 50%;
      background: #f0f2f7;
      div:nth-of-type(1) {
        font-size: 30px;
        font-family: Arial;
        font-weight: bold;
        color: #004a9c;
        text-align: center;
        padding: 94px 0 55px;
      }
      div:nth-of-type(2) {
        margin: 0 auto;
        max-width: 604px;
        font-size: 16px;
        font-family: Arial;
        font-weight: 400;
        color: #5a5b5e;
        line-height: 33px;
      }
    }
  } .about_box_img2 {
      width: 0;
    }
    .about_box_title2 {
      width: 100%;
      div {
        max-width: 1156px !important;
      }
    }
  .about_box:nth-of-type(2n) {
    flex-direction: row-reverse;
  }
}
</style>